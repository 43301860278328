<template>
  <div
    id='inputCsvModal'
    class='modal'
  >
    <div class='modal-dialog modal-dialog-centered'>
      <div class='modal-content' style='width: 500px'>
        <div class='modal-header'>
          データの取り込み
          <button
            id="closeModalBtn"
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="閉じる"
          ></button>
        </div>
        <div class='modal-body m-3 p-3'>
          <div>
            取り込めるデータは以下の通りです。
          </div>
          <div class="m-2" style="font-size: 0.9em">
            ・POPlayerで出力されたファイル（.txt）<br>
            ・Zmeetingで出力されたファイル（.csv）<br>
            ・toittaで出力されたファイル  （.csv）
          </div>
          <form id='csvUploadForm' class="my-3">
            <input
              type='file'
              accept=".csv,.txt"
              id="upload-csv"
              required="true"
              class="form-control d-inline-block my-3"
              @change="isFile = true"
            />
            <div class="d-flex justify-content-center my-3">
              <input
                type="button"
                class='btn btn-primary rounded'
                value="POPlayerへ取り込む"
                @click="uploadInsights"
                :disabled="!isFile"
              >
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import GlobalMethods from '../mixins/globalMethods.js'
  import Encoding from 'encoding-japanese'

  export default {
    mixins: [GlobalMethods],
    emits: ['AddInsightsFromFile'],
    data() {
      return {
        isFile: false
      }
    },
    methods: {
      uploadInsights: function() {
        const self = this
        if (!document.getElementById('csvUploadForm').reportValidity()) return

        const inputElement = document.getElementById('upload-csv')
        const file = inputElement.files[0]
        inputElement.value = null
        self.isFile = false

        const ext = self.getFileExt(file)
        if (ext === 'csv') self.formatCsvToInsights(file)
        else if (ext === 'txt') self.formatTsvToInsights(file)

        document.getElementById('closeModalBtn').click()
      },
      getCharCodeType: function(charArray) {
        return Encoding.detect(charArray)
      },
      formatCsvToInsights: function(file) {
        const self = this
        const reader = new FileReader()
        reader.readAsArrayBuffer(file)

        reader.onload = function(event) {
          const str = event.target.result
          const charArray = new Uint8Array(str)
          const uniArray = Encoding.convert(charArray, 'UNICODE', self.getCharCodeType(charArray))
          const encodeResult = Encoding.codeToString(uniArray)

          // CSVの先頭に全発言という行が存在する場合、その行を破棄する
          // （24.01.23 zmeetingのCSV出力に仕様変更があったと考えられる）
          const fileResult = encodeResult.replace(/^\ufeff/,"").replace(/"/g, "").split(/\r\n|\r|\n/).filter(s => {return s.length > 0})
          if (fileResult[0].match('全発言')) {
            fileResult.shift()
          }

          const header = fileResult.shift().split(',')
          let isToittaCSV = header.includes('発話時刻')
          const timeIndex = header.indexOf(isToittaCSV ? '発話時刻' : '時間')
          const memoIndex = header.indexOf(isToittaCSV ? '書き起こし' : '会話内容')
          if (timeIndex === -1 || memoIndex === -1) {
            alert('不明なフォーマットです。')
            return ''
          }

          const insights = fileResult.map(row => {
            const array = row.split(',')
            const time = array[timeIndex].match(/([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]/g)[0]

            return {
              startTime: time,
              endTime: "",
              memo: self.trimQuotes(array[memoIndex]),
              subMemo: "",
              startSec: self.stringTimeToSec(time),
              isZmeeting: true,
            }
          })
          self.$emit('AddInsightsFromFile', insights)
        }
      },
      formatTsvToInsights: function(file) {
        const self = this
        const reader = new FileReader()
        reader.readAsBinaryString(file)

        reader.onload = function(event) {
          const str = event.target.result
          const sjisArray = str.split('').map(char => char.charCodeAt(0))
          const uniArray = Encoding.convert(sjisArray, 'UNICODE', 'SJIS')
          const encodeResult = Encoding.codeToString(uniArray)
          const fileResult = encodeResult.split(/\r\n|\r|\n/).filter(s => {return s.length > 0})
          const header = fileResult.shift().split(('\t'))
          const startTimeIndex = header.indexOf('開始時間')
          const endTimeIndex = header.indexOf('終了時間')
          const memoIndex = header.indexOf('メモ')
          const subMemoIndex = header.indexOf('補助メモ')
          const insights = fileResult.map(row => {
            const array = row.split('\t')
            return {
              startTime: array[startTimeIndex],
              endTime: endTimeIndex >= 0 ? array[endTimeIndex] : '',
              memo: array[memoIndex],
              subMemo: array[subMemoIndex],
              startSec: self.stringTimeToSec(array[startTimeIndex]),
              isZmeeting: false,
            }
          })
          self.$emit('AddInsightsFromFile', insights)
        }
      },
      getFileExt: function(file) {
        const pos = file.name.lastIndexOf('.')
        if (pos === -1) {
          alert('ファイル拡張子を取得できません')
          return ''
        }
        return file.name.slice(pos + 1)
      },
      stringTimeToSec: function(str) {
        const [hour, min, sec] = str.split(':')
        return Number(sec) + Number(min) * 60 + Number(hour) * 3600
      },
      trimQuotes: function(str){
        return str.replace(/^("|')|("|')$/g, '')
      },
    }
  }
</script>
