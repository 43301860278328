<template>
  <table class='table table-bordered table-sm'>
    <thead>
      <tr>
        <th
          class='px-2 py-1 _sticky'
          colspan='2'
          style='width: 15%'
        >
          開始時間
        </th>
        <th
          class='px-2 py-1 _sticky'
          colspan='2'
          style='width: 15%'
        >
          終了時間
        </th>
        <th
          class='px-2 py-1 _sticky'
          style='width: 35%'
        >
          メモ
        </th>
        <th
          class='px-2 py-1 _sticky'
          style='width: 30%'
        >
          補助メモ
        </th>
        <th
          class='px-2 py-1 _sticky'
          style='width: 5%'
        ></th>
      </tr>
    </thead>

    <tbody>
      <template v-if="insights.length !== 0">

        <tr
          v-for="(insight, index) in insights"
          :key="insight.id"
          :style="getInsightStyle(insight)"
        >
          <td class='bg-light align-middle border-right-0 px-2 py-1'>
            <Popover
              v-if="insight.editMode === 'startTime'"
              placement="bottom-start"
              content='有効な時間を"00:00:00"形式で入力してください'
              :show="insight.popoverTarget === 'startTime'"
              :type="'error'"
            >
              <input
                v-model="insight.startTime"
                class='form-control mousetrap'
                @blur="validateTime({ target: 'startTime', index: index }, $event)"
                @keyup.enter="$event.target.blur()" />
            </Popover>
            <a
              v-else
              @click="jumpTo(insight.startTime)"
              href='#'
            >
              {{ insight.startTime }}
            </a>
          </td>
          <td class='bg-light align-middle text-right border-left-0 px-2 py-1'>
            <button
              v-if="insight.editMode !== 'startTime'"
              :disabled="checkPopoverEnabled(insights)"
              @click="insight.editMode = 'startTime'"
              class='btn btn-sm btn-primary rounded-1'
            >
              <fa icon='pencil-alt' />
            </button>
          </td>
          <td class='bg-light align-middle border-right-0 px-2 py-1'>
            <Popover
              v-if="insight.editMode === 'endTime'"
              placement="bottom-start"
              content='有効な時間を"00:00:00"形式で入力してください'
              :show="insight.popoverTarget === 'endTime'"
              :type="'error'"
            >
              <input
                v-model="insight.endTime"
                @blur="validateTime({ target: 'endTime', index: index }, $event)"
                @keyup.enter="$event.target.blur()"
                class='form-control mousetrap'
              />
            </Popover>
            <a
              v-else
              @click="jumpTo(insight.endTime)"
              href="#"
            >
              {{ insight.endTime }}
            </a>
          </td>
          <td class='bg-light align-middle text-right border-left-0 px-2 py-1'>
            <button
              v-if="insight.editMode !== 'endTime'"
              :disabled="checkPopoverEnabled(insights)"
              @click="insight.editMode = 'endTime'"
              class='btn btn-sm btn-primary rounded-1'
            >
              <fa icon='pencil-alt' />
            </button>
          </td>
          <td
            @click="checkPopoverEnabled(insights) ? null : insight.editMode = 'memo'; adjustTextarea(index);"
            class='align-middle border-right-0 px-2 py-1'
          >
            <textarea
              v-show="insight.editMode === 'memo'"
              v-model="insight.memo"
              ref='adjust_textarea_memo'
              @compositionstart="composing=true"
              @compositionend="composing=false"
              @blur="quitEdit(index)"
              @input="adjustTextarea(index)"
              @keydown.enter="handleKeydown(index)"
              class='form-control mousetrap textarea'
            />
            <span v-show="insight.editMode !== 'memo'">
              {{ insight.memo }}
            </span>
          </td>
          <td
            @click="checkPopoverEnabled(insights) ? null : insight.editMode = 'subMemo'; adjustTextarea(index);"
            class='border-right-0 align-middle px-2 py-1'
          >
            <textarea
              v-show="insight.editMode === 'subMemo'"
              v-model="insight.subMemo"
              ref='adjust_textarea_subMemo'
              @compositionstart="composing=true"
              @compositionend="composing=false"
              @blur="quitEdit(index)"
              @input="adjustTextarea(index)"
              @keydown.enter="handleKeydown(index)"
              class='form-control mousetrap textarea'
            />
            <span v-show="insight.editMode !== 'subMemo'">
              {{ insight.subMemo }}
            </span>
          </td>
          <td class='border-right-0 align-middle text-center px-2 py-1'>
            <button
              class='btn btn-danger btn-sm rounded-1'
              :disabled="checkPopoverEnabled(insights)"
              @click="removeInsight(index)" tabindex='-1'
            >
              <fa icon='trash' />
            </button>
          </td>
        </tr>
      </template>
      <template v-else>
        <tr class='border bg-light p-3 mt-3 small'>
          <td colspan="7">
            まだ発見点がありません
          </td>
        </tr>
      </template>
    </tbody>
  </table>
</template>


<script>
  import GlobalMethods from '../mixins/globalMethods.js';
  import Popover from '../components/Popover.vue';

  export default {
    name: 'InsightTable',
    mixins: [GlobalMethods],
    emits: ['jumpTo', 'addInsights'],
    components: {
      Popover,
    },
    props: {
      insights: {
        type: Array,
        required: true,
        default: () => {
          return []
        },
      },
      currentSeconds: {
        type: Number,
        required: true,
        default: 0,
      },
    },
    data() {
      return {
        composing: false,
      }
    },
    computed: {
      localInsights: {
        get: function() {
          return this.insights;
        },
        set: function(insight) {
          this.$emit('addInsights', insight);
        },
      },
    },
    methods: {
      validateTime: function({ target, index }, e) {
        if (!e.target.value) {
          this.localInsights[index].popoverTarget = ''
          this.localInsights[index].editMode = false;
          return;
        }

        const TIME_PATTERN = '^([01][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$';
        const regex = new RegExp(TIME_PATTERN);
        const result = regex.test(e.target.value);
        if (!result) {
          this.localInsights[index].popoverTarget = target
          e.target.focus();
          return;
        }
        this.localInsights[index].popoverTarget = ''
        this.localInsights[index].editMode = false; 
      },
      removeInsight: function(index) {
        const self = this;
        self.localInsights.splice(index, 1);
      },
      jumpTo: function(timeString) {
        this.$emit('jumpTo', this.stringToTime(timeString));
      },
      stringToTime: function(timeString) {
        return +timeString.split(':').reduce((prev, curr) => (60 * prev) + +curr);
      },
      handleKeydown: function(index) {
        const self = this;
        // 日本語変換を確定する時のEnterで、編集モードが終了してしまうのを止める
        if (self.composing) return;

        self.adjustTextarea(index);
        self.quitEdit(index);
      },
      // 入力フォームの高さを調整する
      adjustTextarea: function(index) {
        const textarea = this.$refs['adjust_textarea_' + this.insights[index].editMode][index];
        const resetHeight = new Promise(function(resolve) {
          resolve(textarea.style.height = 'auto');
        });
        resetHeight.then(function(){
          textarea.style.height = (textarea.scrollHeight) + 'px';
        });
      },
      quitEdit: function(index) {
        let updateInsight = this.insights
        updateInsight[index].editMode = false
      },
      getInsightStyle: function(insight) {
        let style = '';
        if (insight.isZmeeting) {
          style += 'background: rgba(23,162,184,0.2);';
        }
        if (this.convertTimeToSecond(insight.startTime) < this.currentSeconds 
          && this.convertTimeToSecond(insight.endTime) > this.currentSeconds) {
          style += 'background-color: #DAEBF2; !important';
        }
        return style;
      },
    }
  }
</script>

<style scoped>
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    margin: 0px;
  }
  th, td {
    vertical-align: middle;
    padding: 20px 15px;
    font-size: 14px;
  }
  td > span {
    word-break: break-all;
  }
  tbody {
    overflow: scroll;
    border-top: 1px solid #dee2e6 !important;
  }
  .textarea {
    resize: none;
    padding: 5px;
    font-size: 14px;
    line-height: 1.5;
    min-width: 200px;
  }
  .textarea::-webkit-scrollbar{
    display: none;
  }
  /* カラム名固定 */
  ._sticky {
    position: sticky;
    top: -1px;
    background-color: white;
  }
  ._sticky:before {
    content: "";
    position: absolute;
    top: 0px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    border: 1px solid #dee2e6;
  }
</style>
